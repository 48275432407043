<template>
    <div>
        <el-dialog
            title="编辑服务"
            :visible.sync="visible"
            width="800px"
            :close-on-click-modal="false"
            :append-to-body="true"
            @close="close"
            center
        >
            <el-form :inline="false" :model="formData" class="formData" ref="formData" :rules="rules" label-width="180px" v-loading="loading">

                <el-form-item label="服务名称" prop="name">
                    <el-input v-model="formData.name" style="width:350px" maxlength="8" placeholder="请输入服务名称" show-word-limit />
                </el-form-item>
                <el-form-item label="服务编码" >
                    <el-input v-model="formData.code" disabled style="width:350px" maxlength="8" placeholder="请输入服务编码" />
                </el-form-item>
                <el-form-item label="每单位种植面积服务价格" prop="price" >
                    <el-input v-model="formData.price" type="number" style="width:350px" maxlength="11" placeholder="请输入每单位种植面积服务价格" />
                    <span>&ensp;元</span><span style="margin-left: 10px;">每单位种植面积: {{plantingArea}}m²</span>
                </el-form-item>

                <el-form-item label="可应用土地状态" prop="matchLandStatus">
                    <el-radio-group v-model="formData.matchLandStatus" disabled>
                        <el-radio :label="0">待播种</el-radio>
                        <el-radio :label="1">已播种</el-radio>
                        <el-radio :label="2">发芽中</el-radio>
                        <el-radio :label="3">成长中</el-radio>
                        <el-radio :label="4">可收获</el-radio>
                    </el-radio-group>
                </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="close">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import * as formRules from '@/assets/js/formRules'

import { URL } from "../../../../config/url.supplier";

export default {
    name: '',
    props: {
		dialogVisible:{
			type: Boolean,
			default: false
		},
		currentRow:{
			type:Object,
			default: () => {}
		}
	},
    data() {
        return {
            visible: false,
            loading: false,
            formData: {
                name: '',
                code: '',
                price: '',
                matchLandStatus: '',
                id: ''
            },
            plantingArea: '--',
            formRules,
            rules: {
                name: [
                    { required: true, message: '请输入服务名称', trigger: 'blur' },
                ],
                code: [
                    { required: true, message: '不可修改', trigger: 'blur' },
                ],
                price: [
                    { required: true, message: '请输入每单位服务价格', trigger: 'change' },
                ],
                status: [
                    { required: true, message: '至少勾选一个', trigger: 'change' }
                ],
            },
        }
    },
    methods:  {
        getlanddistrictList() {
            this.axios.get(URL.farm.farmLanddistrictList).then(res => {
                if (res.code === "0") {
                    this.plantingArea = res.data[0].plantMinArea;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        close() {
            this.$emit('closeEdit')
        },

        submit() {
            this.$refs.formData.validate((valid) => {
                if(!valid) { return }
                this.loading = true;
                let url = URL.farm.farmServiceTypePriceUpdate;
                this.axios.put(url, this.formData).then(res=>{
                    if(res.code === '0'){
                        this.$message.success('操作成功');
                        this.close();
                        this.$emit('resetList')
                    }else{
                        this.$message.error(res.msg);
                    }
                    this.loading = false;
                })
            })
        }
    },

    watch:{
        currentRow: {
            handler: function(oldV, newV) {
                console.log(oldV, '33333');
                const { name, code, price, matchLandStatus, id } = oldV;
                this.formData = {
                    name,
                    code,
                    price,
                    matchLandStatus,
                    id,
                }
            },
            deep: true
        },
        dialogVisible: function(val) {
            this.visible = val;
        }

    },

    mounted() {
        this.getlanddistrictList()
    }

}
</script>

<style lang="less" scoped>
/deep/ .el-form-item__content {
    min-width: 140px !important;
}
</style>
