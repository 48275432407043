<template>
    <div class="pages" v-loading="loading">
        <table-height-auto>
            <template slot="before">
                <el-form :inline="true" class="demo-form-inline">
                    <el-form-item label="服务名称:">
                        <el-input
                            v-model="search.name"
                            clearable
                            placeholder="模糊搜索"
                            style="width: 160px;"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="服务编码:">
                        <el-input
                            v-model="search.code"
                            clearable
                            placeholder="精确搜索"
                            style="width: 160px;"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="状态:">
                        <el-select
                            v-model="search.enable"
                            placeholder=""
                            style="width: 160px;"
                        >
                            <el-option label="全部" value="" />
                            <el-option label="禁用" value="0" />
                            <el-option label="启用" value="1" />
                        </el-select>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" size="medium" @click="getList"
                            >查询</el-button
                        >
                        <el-button type="primary" size="medium" @click="reset"
                            >重置</el-button
                        >
                        <!-- <el-button type="primary" size="medium" @click="edit({})">编辑服务</el-button> -->
                    </el-form-item>
                </el-form>
            </template>

            <template v-slot:table="row">
                <el-table
                    v-if="row.height"
                    :height="row.heightText"
                    :data="tableData"
                    style="width: 100%"
                >
                    <el-table-column type="index" width="50" label="序号" />
                    <el-table-column prop="name" label="服务名称" />
                    <el-table-column
                        prop="code"
                        label="服务编码"
                        align="center"
                    />
                    <el-table-column
                        prop="price"
                        label="每单位种植面积服务价格"
                        show-overflow-tooltip
                    />

                    <el-table-column
                        prop="matchLandStatus"
                        label="可应用土地状态"
                        width="180"
                    >
                        <template #default="{row}">
                            {{
                                row.matchLandStatus | matchLand
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="enable" label="状态" width="140">
                        <template #default="scope">
                            {{
                                scope.row.enable == 0
                                    ? "禁用"
                                    : scope.row.enable == 1
                                    ? "启用"
                                    : ""
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="date" label="操作" align="center">
                        <template #default="scope">
                            <el-button
                                type="primary"
                                size="medium"
                                @click="onOff(scope.row, 0)"
                                v-if="scope.row.enable == 1"
                                >禁用</el-button
                            >
                            <el-button
                                type="primary"
                                size="medium"
                                @click="onOff(scope.row, 1)"
                                v-if="scope.row.enable == 0"
                                >启用</el-button
                            >
                            <el-button
                                type="primary"
                                size="medium"
                                @click="edit(scope.row)"
                                >编辑</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </template>

            <template slot="after">
                <div class="paging-row">
                    <el-pagination
                        v-if="total"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[5, 10, 15, 20]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total"
                    >
                    </el-pagination>
                </div>
            </template>
        </table-height-auto>
        <editService
            @resetList="getList"
            @closeEdit="closeEdit"
            :dialogVisible="editDialogVisible"
            :currentRow="currentRow"
        />
    </div>
</template>

<script>
import editService from "./components/editService.vue";
import { URL } from "../../../config";

export default {
    name: "packageManage",
    components: {
        editService
    },
    data() {
        return {
            search: {
                enable: "",
                code: "",
                name: ""
            },
            loading: false,
            tableData: [],
            currentPage: 1,
            total: 0,
            pageSize: 10,
            serviceTypeList: [],
            editDialogVisible: false,
            currentRow: {}
        };
    },
    filters:{
        matchLand(val){
            switch (val) {
                case 1:
                    return '待播种';
                case 2:
                    return '已播种,发芽中,成长中';
                case 3:
                    return '可收获';
            }
        }
    },
    methods: {
        getList() {
            this.axios
                .post(URL.farm.farmServiceTypePriceList, {
                    ...this.search,
                    pageNo: this.currentPage,
                    pageSize: this.pageSize
                })
                .then(res => {
                    console.log(res);
                    if (res.code === "0") {
                        this.tableData = res.data;
                        this.total = res.data.total;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },

        onOff(row, enable) {
            this.$confirm(
                `是否切换状态为${enable == 0 ? "禁用" : "启用"},${
                    enable == 0 ? "禁用" : "启用"
                }的服务类型${enable == 0 ? "不会" : "会"}在APP端显示`,
                `${enable == 0 ? "禁用" : "启用"}提示`,
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }
            )
                .then(() => {
                    this.axios
                        .put(URL.farm.farmServiceTypePriceUpdateEnableStatus, {
                            enable,
                            id: row.id
                        })
                        .then(res => {
                            if (res.code === "0") {
                                this.getList();
                                this.$message.success("操作成功");
                            } else {
                                this.$message.error(res.msg);
                            }
                        });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消"
                    });
                });
        },

        off() {
            this.$confirm("是否切换状态为禁用，禁用的服务类型不显示在APP上")
                .then(_ => {
                    done();
                })
                .catch(_ => {});
        },

        edit(row) {
            this.editDialogVisible = true;
            this.currentRow = row;
        },

        closeEdit() {
            this.editDialogVisible = false;
        },

        reset() {
            this.search = {
                enable: "",
                code: "",
                name: ""
            }
            this.getList();
        },

        handleSizeChange(val, type) {
            this.pageSize = val;
            this.currentPage = 1;
            this.getList();
        },

        handleCurrentChange(val, type) {
            this.currentPage = val;
            this.getList();
        }
    },
    mounted() {
        this.getList();
    }
};
</script>

<style lang="less" scoped>
.pages {
    .search {
        display: flex;
        justify-content: space-between;
    }
}

.avatar {
    width: 60px;
    height: 60px;
    border-radius: 10px;
}
</style>
